@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --primary-color: #8B5CF6;
  --primary-light: #A78BFA;
  --primary-dark: #7C3AED;
  --secondary-color: #C084FC;
  --accent-color: #F472B6;
  --background-dark: #0F172A;
  --background-gradient: linear-gradient(135deg, #1E1E2F 0%, #2D1B4E 100%);
  --text-color: #E2E8F0;
  --light-text-color: #ffffff;
  --card-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --hover-transform: translateY(-5px);
  --gradient-primary: linear-gradient(135deg, #FF3366, #8B5CF6);
  --neon-glow: 0 0 10px rgba(139, 92, 246, 0.5), 
               0 0 20px rgba(139, 92, 246, 0.3), 
               0 0 30px rgba(139, 92, 246, 0.2);
  --neon-text-glow: 0 0 5px rgba(255, 255, 255, 0.5),
                    0 0 10px rgba(139, 92, 246, 0.5);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Slab", serif;
}

body {
  background: var(--background-dark);
  color: var(--text-color);
  line-height: 1.6;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Hero Section */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  background: var(--background-gradient);
  color: var(--light-text-color);
  text-align: center;
  padding: 50px 20px;
  position: relative;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 50%, rgba(255, 51, 102, 0.1) 0%, transparent 50%),
    radial-gradient(circle at 80% 50%, rgba(139, 92, 246, 0.1) 0%, transparent 50%);
  pointer-events: none;
}

.AppContainer {
  position: relative;
  z-index: 1;
}

.AppLogo {
  max-width: 250px;
  margin-bottom: 40px;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3));
  transition: all 0.3s ease;
  animation: neonPulse 3s infinite;
}

@keyframes neonPulse {
  0% {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3))
            drop-shadow(0 0 20px rgba(139, 92, 246, 0.2));
  }
  25% {
    filter: drop-shadow(0 0 15px rgba(255, 51, 102, 0.5))
            drop-shadow(0 0 30px rgba(255, 51, 102, 0.3));
  }
  50% {
    filter: drop-shadow(0 0 25px rgba(139, 92, 246, 0.8))
            drop-shadow(0 0 40px rgba(139, 92, 246, 0.5))
            drop-shadow(0 0 60px rgba(139, 92, 246, 0.3));
  }
  75% {
    filter: drop-shadow(0 0 15px rgba(255, 51, 102, 0.5))
            drop-shadow(0 0 30px rgba(255, 51, 102, 0.3));
  }
  100% {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3))
            drop-shadow(0 0 20px rgba(139, 92, 246, 0.2));
  }
}

.AppLogo:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 0 30px rgba(139, 92, 246, 0.8))
         drop-shadow(0 0 50px rgba(139, 92, 246, 0.6))
         drop-shadow(0 0 70px rgba(139, 92, 246, 0.4));
}

.AppTitle {
  font-size: 5.5rem;
  margin-bottom: 25px;
  font-weight: 700;
  letter-spacing: -0.02em;
  text-shadow: var(--neon-text-glow);
  background: linear-gradient(to right, #fff, #E2E8F0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.AppParagraph {
  font-size: 1.5rem;
  font-weight: 400;
  max-width: 800px;
  margin: 0 auto;
  opacity: 0.9;
  text-shadow: 0 0 10px rgba(139, 92, 246, 0.3);
  letter-spacing: 0.5px;
  margin-bottom: 40px;
}

/* Navigation Buttons */
.nav-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.nav-button {
  background: rgba(139, 92, 246, 0.1);
  color: var(--light-text-color);
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 500;
  border: 1px solid rgba(139, 92, 246, 0.3);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  animation: buttonNeon 2s infinite alternate;
}

@keyframes buttonNeon {
  from {
    box-shadow: 0 0 10px rgba(139, 92, 246, 0.5),
                0 0 20px rgba(139, 92, 246, 0.3),
                0 0 30px rgba(139, 92, 246, 0.2);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5),
                0 0 10px rgba(139, 92, 246, 0.5);
  }
  to {
    box-shadow: 0 0 15px rgba(139, 92, 246, 0.6),
                0 0 25px rgba(139, 92, 246, 0.4),
                0 0 35px rgba(139, 92, 246, 0.3);
    text-shadow: 0 0 7px rgba(255, 255, 255, 0.6),
                0 0 12px rgba(139, 92, 246, 0.6);
  }
}

.nav-button:hover {
  background: rgba(139, 92, 246, 0.2);
  border-color: var(--primary-light);
  color: var(--light-text-color);
  box-shadow: 0 0 20px rgba(139, 92, 246, 0.8),
              0 0 30px rgba(139, 92, 246, 0.6),
              0 0 40px rgba(139, 92, 246, 0.4);
}

/* Responsive adjustments for nav buttons */
@media screen and (max-width: 768px) {
  .nav-buttons {
    gap: 15px;
  }
  
  .nav-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .nav-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .nav-button {
    width: 200px;
    text-align: center;
  }
}

/* Common Section Styles */
.section {
  padding: 80px 20px;
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(15, 23, 42, 0.7);
  border-radius: 20px;
  margin-top: 40px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(139, 92, 246, 0.1);
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 50px;
  text-align: center;
  color: var(--light-text-color);
  position: relative;
  font-weight: 700;
  text-shadow: var(--neon-text-glow);
}

.section-title::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background: var(--gradient-primary);
  margin: 15px auto 0;
  border-radius: 2px;
  box-shadow: var(--neon-glow);
}

/* Services Section */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
}

.service-card {
  background: rgba(255, 255, 255, 0.03);
  padding: 30px;
  border-radius: 16px;
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
  border: 1px solid rgba(139, 92, 246, 0.1);
  backdrop-filter: blur(5px);
}

.service-card:hover {
  transform: var(--hover-transform);
  box-shadow: var(--neon-glow);
  border-color: var(--primary-light);
}

.service-card h3 {
  color: var(--light-text-color);
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 600;
  text-shadow: var(--neon-text-glow);
}

.service-card p {
  color: var(--text-color);
  opacity: 0.9;
}

/* About Section */
.about-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.about-content p {
  margin-bottom: 40px;
  font-size: 1.1rem;
  opacity: 0.9;
  color: var(--text-color);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.stat-item {
  text-align: center;
  padding: 30px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  box-shadow: var(--card-shadow);
  transition: transform 0.3s ease;
  border: 1px solid rgba(139, 92, 246, 0.1);
  backdrop-filter: blur(5px);
}

.stat-item:hover {
  transform: var(--hover-transform);
  box-shadow: var(--neon-glow);
}

.stat-item h4 {
  font-size: 3rem;
  margin-bottom: 10px;
  font-weight: 700;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: var(--neon-text-glow);
}

.stat-item p {
  font-size: 1.1rem;
  color: var(--text-color);
  opacity: 0.9;
}

/* Portfolio Section */
.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 30px;
}

.portfolio-item {
  background: rgba(255, 255, 255, 0.03);
  padding: 30px;
  border-radius: 16px;
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
  border: 1px solid rgba(139, 92, 246, 0.1);
  backdrop-filter: blur(5px);
}

.portfolio-item:hover {
  transform: var(--hover-transform);
  box-shadow: var(--neon-glow);
  border-color: var(--primary-light);
}

.portfolio-item h3 {
  color: var(--light-text-color);
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 600;
  text-shadow: var(--neon-text-glow);
}

.portfolio-item p {
  color: var(--text-color);
  opacity: 0.9;
}

/* Contact Section */
.contact-content {
  text-align: center;
  background: rgba(255, 255, 255, 0.03);
  padding: 50px;
  border-radius: 16px;
  box-shadow: var(--card-shadow);
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid rgba(139, 92, 246, 0.1);
  backdrop-filter: blur(5px);
}

.contact-info p {
  margin: 15px 0;
  font-size: 1.1rem;
  color: var(--text-color);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.contact-info p:hover {
  color: var(--primary-light);
  transform: translateX(5px);
  text-shadow: var(--neon-text-glow);
}

.contact-button {
  display: inline-block;
  background: var(--gradient-primary);
  color: var(--light-text-color);
  padding: 15px 40px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 20px;
  box-shadow: var(--neon-glow);
  transition: all 0.3s ease;
  font-size: 1.2rem;
  text-shadow: var(--neon-text-glow);
  border: 1px solid rgba(139, 92, 246, 0.3);
}

.contact-button:hover {
  box-shadow: 0 0 15px rgba(139, 92, 246, 0.8),
              0 0 30px rgba(139, 92, 246, 0.6),
              0 0 45px rgba(139, 92, 246, 0.4);
  color: var(--light-text-color);
  text-decoration: none;
  border-color: var(--primary-light);
}

/* Footer */
.footer {
  background: var(--background-gradient);
  color: var(--light-text-color);
  text-align: center;
  padding: 30px 0;
  margin-top: 80px;
  border-top: 1px solid rgba(139, 92, 246, 0.1);
}

.footer p {
  margin: 0;
  opacity: 0.9;
  text-shadow: var(--neon-text-glow);
}

/* Responsive Design */
@media screen and (max-width: 950px) {
  .AppLogo {
    max-width: 200px;
  }
  
  .AppTitle {
    font-size: 4rem;
  }

  .AppParagraph {
    font-size: 1.3rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .stat-item h4 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 375px) {
  .AppLogo {
    max-width: 150px;
  }
  
  .AppTitle {
    font-size: 3rem;
  }

  .AppParagraph {
    font-size: 1.1rem;
  }

  .section-title {
    font-size: 1.75rem;
  }

  .service-card,
  .portfolio-item,
  .contact-content {
    padding: 20px;
  }
}
